import React, { useState } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Form.css'; // Import the CSS file

function Form() {
  const navigate = useNavigate();
//frontend values
  const [formData, setFormData] = useState({
    controlnumber: '',
    issuenumber: '',
    issue_date: '',
    uid: '',
    revise_no: '',
    revise_date: '',
    date: '',
    projectUnit: '',
    Unit_Under_Test:'',
    si_no: '',
    Qty: '',
    unit_weight: '',
    fixture_weight: '',
    contact_person: '',
    mobile_no: '',
    company_name: '',
    environment_test:[],
    noise_vibration_measurement_tests: [],
    fluid_flow_test: [],
    test_standards: '',
    test_specifications: '',
     uncertainity_statement_in_test_report:'',
    statement_of_confirmity: '',
    decesion_rule_communicated_with_customers:[],
    conformance: 'Case a',
    specify: [],
    if_yes_pls_specify: '',
    record_of_change_request: '',
    requested_by: '',
    inspectedBySignature: '',
    customerSignature: '',
    test_start_date:'',
    uut_receipt_date:'',
    test_completion_date:'',
    test_equipment:'',
    remarks:'',
    requesition_accepted:'',
    unit_condition:'',
    wheather_repeat_test:'',
    remarks_if_any:'',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRadio = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
  
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };
 

  const environmentalTestsList = [
    'Vibration',
    'Shock',
    'Ship motion',
    'Acceleration',
    'Bump',
    'Tilt',
    'Low temperature',
    'High Temperature',
    'Burn in',
    'Damp Heat',
    'Thermal Shock',
    'Thermal Cycling',
    'Tropical Exposure',
    'Mould Growth',
    'Salt spray',
    'Drip Test',
    'Sand & Dust test (IPX5 &IPX6)',
    'Driving rain test(IP1X, IP2X, IP5X, IP6X,IP7X& IP8X)'
  ];
  
const specifyList=[
 'As per standard requirement' ,
 'As per customer requirement',
 'No external physical abnormalities found in EUT',
 'EUT is in Switched on Condition',
 'Others',
];
  const noiseVibrationTestsList = [
    'Structure Borne Noise',
    'Air Borne Noise'
  ];
 
  const decesion_rule_communicated_with_customerslist = [
    'Yes',
    'No'
  ];
  
 const   uncertainity_statement_in_test_reportlist=[
  'Required',
  'Not Required'
 ]
 const record_of_change_requestlist=[
  'Yes',
  'No',
 ]
//  const statementOfConformityLists=[
//   'Yes',
//   'No',
//  ];
//  const decisionRuleAgreedOptions=[
//   'Yes',
//   'No',
//  ];
 
  const fluidFlowTestsList = [
    'Burst Test',
    'Pneumatic Test',
    'Dismantling & Reassembly',
    'Life Cycle Test',
    'Flow Coefficient Hydraulic Test',
    'Hydraulic Proof',
    'Other'
  ];
  const requesition_acceptedlist= [
  'yes',
  'no',
  ];
  const  wheather_repeat_testlist= [
    'yes',
    'no',
    ];
    const  statement_of_confirmitylist= [
      'yes',
      'no',
      ];

 
  const handleChan = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        environment_test: [...prevState.environment_test, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        environment_test: prevState.environment_test.filter(test => test !== value)
      }));
    }
  };
 


  const handleC = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        noise_vibration_measurement_tests: [...prevState.noise_vibration_measurement_tests, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        noise_vibration_measurement_tests: prevState.noise_vibration_measurement_tests.filter(test => test !== value)
      }));
    }
  };
  



  const handlespecify = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        specify: [...prevState.specify, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        specify: prevState.specify.filter(test => test !== value)
      }));
    }
  };


 


  const handlefluid= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        fluid_flow_test: [...prevState.fluid_flow_test, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        fluid_flow_test: prevState.fluid_flow_test.filter(test => test !== value)
      }));
    }
  };
  // jkhgytryouiopolikgjfht
  const handledecision= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        decesion_rule_communicated_with_customers: [...prevState.decesion_rule_communicated_with_customers, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        decesion_rule_communicated_with_customers: prevState.decesion_rule_communicated_with_customers.filter(test => test !== value)
      }));
    }
  };
 
  const handlereport= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        uncertainity_statement_in_test_report: [...prevState.uncertainity_statement_in_test_report, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        uncertainity_statement_in_test_report: prevState.uncertainity_statement_in_test_report.filter(test => test !== value)
      }));
    }
  };
  const handlerequest= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        record_of_change_request: [...prevState.record_of_change_request, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        record_of_change_request: prevState.record_of_change_request.filter(test => test !== value)
      }));
    }
  };
 
  const handlerepeat= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        wheather_repeat_test: [...prevState. wheather_repeat_test, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        wheather_repeat_test: prevState. wheather_repeat_test.filter(test => test !== value)
      }));
    }
  };

  const handleconformity= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        statement_of_confirmity: [...prevState. statement_of_confirmity, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        statement_of_confirmity: prevState. statement_of_confirmity.filter(test => test !== value)
      }));
    }
  };


  const handleaccepted= (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData(prevState => ({
        ...prevState,
        requesition_accepted: [...prevState.requesition_accepted, value]
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        requesition_accepted:prevState. requesition_accepted.filter(test => test !== value)
      }));
    }
  };
  

 
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   navigate('/formdata', { state: formData });
  // };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     navigate('/formdata', { state: formData });
//     try {
//         // const response = await axios.post('http://192.168.0.103:8000/submit-test-requisition/', formData);
//         const response = await axios.post('http://192.168.0.167:8000/submit-test-requisition/', formData);
//         console.log(response.data); // Log the response data
//         // Handle success
//     } catch (error) {
//         console.error('Error:', error); // Log any errors
//         // Handle error
//     }
// };
const handleSubmit = async (e) => {
  e.preventDefault(); // Prevent the default form submission behavior

  // Navigate to '/formdata' with the form data as state
  navigate('/formdata', { state: formData });

  try {
      // Send a POST request to the server endpoint with the form data
      const response = await axios.post('http://192.168.1.34:8000/submit-test-requisition/', formData);
      console.log(response.data); // Log the response data
      // Handle success
  } catch (error) {
      console.error('Error:', error); // Log any errors
      // Handle error
  }
};


  return (
    <div className="form-container">
      <h2 className="form-title">TEST REQUISITION FORM</h2>
      <div className='containercontr'>
      <div className='form-group'>
      
        <label htmlFor='controlnumber'>Control No:</label>
        <input
          type='text'
          id='controlnumber'
          name='controlnumber'
          value={formData.controlnumber}
          // onChange={handleChange}
          onChange={handleChange}
        />
      </div>
      {/* <input
  type='text'
  id='controlNo'
  name='controlNo'
  value={controlNo}
  onChange={(e) => setControlNo(e.target.value)}
/> */}
      <div className='form-group'>
         <label htmlFor='issuenumber'>Issue No:</label>
         <input
           type='text'
           id='issuenumber'
           name='issuenumber'
           value={formData.issuenumber}
         onChange={handleChange}
         />
      </div> 
     
          

      <div className='form-group'>
        <label htmlFor='issue_date'>Issue Date:</label>
        <input
          type='text'
          id='issue_date'
          name='issue_date'
          value={formData.issue_date}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <label htmlFor='uid'>UID:</label>
        <input
          type='text'
          id='uid'
          name='uid'
          value={formData.uid}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <label htmlFor='revise_no'>Rev No:</label>
        <input
          type='text'
          id='revise_no'
          name='revise_no'
          value={formData.revise_no}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <label htmlFor='revise_date'>Rev Date:</label>
        <input
          type='text'
          id='revise_date'
          name='revise_date'
          value={formData.revise_date}
          onChange={handleChange}
        />
      </div>
      <div className='form-group'>
        <label htmlFor='date'>Date:</label>
        <input
          type='text'
          id='date'
          name='date'
          value={formData.date}
          onChange={handleChange}
        />
      </div>
    </div>
     
      <form onSubmit={handleSubmit}>
        <div className="containercontr">
        <div className="form-group">
          <label htmlFor="project" className="form-label">Project Unit</label>
          <input type="text" id="project" name="project" value={formData.project} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="Unit_Under_Test" className="form-label">Unit under test:</label>
          <input type="text" id="Unit_Under_Test" name="Unit_Under_Test" value={formData.Unit_Under_Test} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="si_no" className="form-label">SI No</label>
          <input type="text" id="si_no" name="si_no" value={formData.si_no} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">       
            <label htmlFor="Qty" className="form-label">Quantity</label>
           <input type="text" id="Qty" name="Qty" value={formData.Qty} onChange={handleChange} className="form-input" />        </div>
           <div className="form-group">
           <label htmlFor="unit_weight" className='form-label'>Unit Weight (kg)</label>
           <input type="text" id="unit_weight" name="unit_weight" value={formData.unit_weight} onChange={handleChange} className="form-input"/>
         </div>
         <div className="form-group">
          <label htmlFor="fixture_weight" className="form-label">Fixture Weight</label>
          <input type="text" id="fixture_weight" name="fixture_weight" value={formData.fixture_weight} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
           <label htmlFor="contact_person" className="form-label">Contact Person</label>
          <input type="text" id="contact_person" name="contact_person" value={formData.contact_person} onChange={handleChange} className="form-input" />
        </div>
         <div className="form-group">
           <label htmlFor="mobile_no" className="form-label">Phone Number</label>
           <input type="text" id="mobile_no" name="mobile_no" value={formData.mobile_no} onChange={handleChange} className="form-input" />
         </div>
         <div className="form-group">
           <label htmlFor="company_name" className="form-label">Company Name</label>
           <input type="text" id="company_name" name="company_name" value={formData.company_name} onChange={handleChange} className="form-input" />
        </div>
        </div>
        {/* Add other input fields */}

        <div>
        <h2 className="select-tests-header">NAME OF TEST:</h2>
        </div>
<div>
      <h2> Environmental Test</h2>
      <form onSubmit={handleSubmit}>
        {environmentalTestsList.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handleChan} />
              {test}
            </label>
          </div>
        ))}
      
      </form>
    </div>


    
    <div>
  <h2> Noise & Vibration Measurement Test</h2>
  <form onSubmit={handleSubmit}>
    {noiseVibrationTestsList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handleC} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>


<div>
  <h2>FLUID FLOW TEST   </h2>
  <form onSubmit={handleSubmit}>
    {fluidFlowTestsList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handlefluid} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>

   
<div className="form-group">
  <label htmlFor="test_standards" className="form-label">Test Standards</label>
  <textarea 
    id="test_standards" 
    name="test_standards" 
    value={formData.test_standards} 
    onChange={handleChange} 
    className="form-input" 
  />
</div>

{/* <div className="form-group">
  <label htmlFor="testStandards" className="form-label">Test Standards</label>
  <textarea id="testStandards" name="testStandards" value={formData.testStandards} onChange={handleChange} className="form-textarea" />
</div> */}


<div className="form-group">
  <label htmlFor="test_specifications" className="form-label">Test Specifications</label>
  <textarea 
    id="test_specifications" 
    name="test_specifications" 
    value={formData.test_specifications} 
    onChange={handleChange} 
    className="form-input" 
  />
</div>



{/* <div>
      <h2>Uncertainty Statement to be stated in Test Report </h2>
      <label>
        <input type="radio" name="uncertainity_statement_in_test_report" value="Yes" />
        Yes
      </label>
      <label>
        <input type="radio" name="uncertainity_statement_in_test_report" value="No" />
        No
      </label>
      <formData />
    </div> */}

<div>
      <h2>decesion_rule_communicated_with_customers </h2>
      <form onSubmit={handleSubmit}>
        {decesion_rule_communicated_with_customerslist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handledecision} />
              {test}
            </label>
          </div>
          
          
        ))}
      
      </form>
    </div>





    <div>
      <h2>Uncertainty Statement to be stated in Test Report </h2>
      <form onSubmit={handleSubmit}>
        {uncertainity_statement_in_test_reportlist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handlereport} />
              {test}
            </label>
          </div>
          
          
        ))}
      
      </form>
    </div>


    <div>
  <h2> Noise & Vibration Measurement Test</h2>
  <form onSubmit={handleSubmit}>
    {noiseVibrationTestsList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handleC} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>




    
      {/* <h2>Mention the Statement of conformity </h2> */}
      
      <div>
  <h2> Mention the Statement of conformity </h2>
  <form onSubmit={handleSubmit}>
    {statement_of_confirmitylist.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handleconformity} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>

    {/* <h2>Decision Rule (Communicated to and agreed with the customer)</h2> */}
    
   
       
        <div className="form-group">
          <label htmlFor="conformance" className="form-label">Conformance</label>
          <select id="conformance" name="conformance" value={formData.conformance} onChange={handleChange} className="form-input">
            <option value="Case a">Case a :Conformance</option>
            <option value="Case b">Case b: Conditional Conformance </option>
            <option value="Case c">Case c : Conditional non-Conformance </option>
            <option value="Case d">Case d : non-Conformance</option>
          </select>
        </div>
        <div>
       <h5> If Conformity statement required the customer has to specify in the test request.</h5></div>
       <div>
  <h2>If Yes Pls. Specify:- </h2>
  <form onSubmit={handleSubmit}>
    {specifyList.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handlespecify} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>


     {/* <h2>Record of Change Request(s)(if any) </h2> */}
<div>
     <h2>Record of Change Request(s)(if any)</h2>
  <form onSubmit={handleSubmit}>
    {record_of_change_requestlist.map(test => (
      <div key={test}>
        <label htmlFor={test}>
          <input type="checkbox" id={test} name={test} value={test} onChange={handlerequest} />
          {test}
        </label>
      </div>
    ))}
  </form>
</div>
       
    <div className="form-group">
          <label htmlFor="if_yes_pls_specify" className="form-label">If Yes Pls. Specify	</label>
          <input type="text" id="if_yes_pls_specify" name="if_yes_pls_specify" value={formData.if_yes_pls_specify} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="requested_by" className="form-label">Requested By</label>
          <input type="text" id="requested_by" name="requested_by" value={formData.requested_by} onChange={handleChange} className="form-input" />
        </div>
        
        <div className="form-group">
          <label htmlFor="inspectedBySignature" className="form-label">Signature (Inspected By)</label>
          <input type="text" id="inspectedBySignature" name="inspectedBySignature" value={formData.inspectedBySignature} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
          <label htmlFor="customerSignature" className="form-label">Signature (Customer)</label>
          <input type="text" id="customerSignature" name="customerSignature" value={formData.customerSignature} onChange={handleChange} className="form-input" />
        </div>
        {/* <button type="submit" className="form-button" onClick={upload}>Submit</button> */}
        <button type="submit" className="form-button">Submit</button>
      </form>
{/* jlfhuahklrhihe */}
<form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="test_start_date" className="form-label">Test Start Date:</label>
          <input type="text" id="test_start_date" name="test_start_date" value={formData.test_start_date} onChange={handleChange} className="form-input" />
        </div>
        {/* <div className="form-group">
          <label htmlFor="Unit_Under_Test" className="form-label">Unit_Under_Test</label>
          <input type="text" id="Unit_Under_Test" name="Unit_Under_Test" value={formData.Unit_Under_Test} onChange={handleChange} className="form-input" />
        </div> */}
        <div className="form-group">
          <label htmlFor="uut_receipt_date" className="form-label">uut_receipt_date</label>
          <input type="text" id="uut_receipt_date" name="uut_receipt_date" value={formData.uut_receipt_date} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">       
            <label htmlFor="test_completion_date" className="form-label">test_completion_date</label>
           <input type="text" id="test_completion_date" name="test_completion_date" value={formData.test_completion_date} onChange={handleChange} className="form-input" />        </div>
           <div className="form-group">
           <label htmlFor="test_equipment" className='form-label'>test_equipment</label>
           <input type="text" id="test_equipment" name="test_equipment" value={formData.test_equipment} onChange={handleChange} className="form-input"/>
         </div>
         {/* <div className="form-group">
          <label htmlFor="remarks" className="form-label">remarks </label>
          <input type="text" id="remarks" name="remarks" value={formData.remarks} onChange={handleChange} className="form-input" />
        </div> */}
        
<div className="form-group">
  <label htmlFor="remarks" className="form-label">Remarks</label>
  <textarea 
    id="remarks" 
    name="remarks" 
    value={formData.remarks} 
    onChange={handleChange} 
    className="form-input" 
  />
</div>
        <div className="form-group">
           <label htmlFor="total_time_of_equipment_use" className="form-label">total_time_of_equipment_use </label>
          <input type="text" id="total_time_of_equipment_use" name="total_time_of_equipment_use" value={formData.total_time_of_equipment_use} onChange={handleChange} className="form-input" />
        </div>


        <div>
      <h2>Requesition_accepted</h2>
      <form onSubmit={handleSubmit}>
        {requesition_acceptedlist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handleaccepted} />
              {test}
            </label>
          </div>
        ))}
      
      </form>
    </div>

    
      
      {/* <Whether Repeat test */}
      <div>
      <h2>Whether Repeat test</h2>
      <form onSubmit={handleSubmit}>
        { wheather_repeat_testlist.map(test => (
          <div key={test}>
            <label htmlFor={test}>
              <input type="checkbox" id={test} name={test} value={test} onChange={handlerepeat} />
              {test}
            </label>
          </div>
        ))}
      
      </form>
    </div>

         <div className="form-group">
           <label htmlFor="unit_condition" className="form-label">unit_condition</label>
           <input type="text" id="unit_condition" name="unit_condition" value={formData.unit_condition} onChange={handleChange} className="form-input" />
        </div>
        <div className="form-group">
  <label htmlFor="remarks_if_any" className="form-label">Remarks If Any</label>
  <textarea 
    id="remarks_if_any" 
    name="remarks_if_any" 
    value={formData.remarks_if_any} 
    onChange={handleChange} 
    className="form-input" 
  />
</div>
        </form>
    </div>
  );
}

export default Form;










