
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Formdata.css';
import image from "../assets/logo.jpeg";
import data from "../assets/image.png";


const environmentalTestsList = [
  'Vibration',
  'Shock',
  'Ship motion',
  'Acceleration',
  'Bump',
  'Tilt',
  'Low temperature',
  'High Temperature',
  'Burn in',
  'Damp Heat',
  'Thermal Shock',
  'Thermal Cycling',
  'Tropical Exposure',
  'Mould Growth',
  'Salt spray',
  'Drip Test',
  'Sand & Dust test (IPX5 &IPX6)',
  'Driving rain test(IP1X, IP2X, IP5X, IP6X,IP7X& IP8X)'
];
const noiseVibrationTestsList = [
  'Structure Borne Noise',
  'Air Borne Noise'
];
  const requesition_acceptedlist= [
  'yes',
  'no',
  ];
const specifyList=[
  'As per standard requirement' ,
  'As per customer requirement',
  'No external physical abnormalities found in EUT',
  'EUT is in Switched on Condition',
  'Others',
 ];

const fluidFlowTestsList = [
  'Burst Test',
  'Pneumatic Test',
  'Dismantling & Reassembly',
  'Life Cycle Test',
  'Flow Coefficient Hydraulic Test',
  'Hydraulic Proof',
  'Other'
];
const record_of_change_requestlist=[
  'Yes',
  'No',
 ]
const decesion_rule_communicated_with_customerslist=[
  'Yes',

  'No'
  ];
   const uncertainity_statement_in_test_reportlist=[
    'Required',
  
    'Not Required'
    ];
    const  wheather_repeat_testlist= [
      'yes',
      'no',
      ];
      const  statement_of_confirmitylist= [
        'yes',
        'no',
        ];
  
function Formdata() {
  const location = useLocation();
  const formData = location.state;


  
  // Check if formData exists before accessing its properties
  if (!formData) {
    return <div>No form data available</div>;
  }
  // const selectedOption = document.querySelector('input[name="decesion_rule_communicated_with_customers"]:checked')?.value;
  // const selectedOptionmention = document.querySelector('input[name="statement_of_confirmity"]:checked')?.value;
  // const selectedOptionreport = document.querySelector('input[name="uncertainity_statement_in_test_report"]:checked')?.value;
  // const selectedOptionrequest = document.querySelector('input[name="record_of_change_request"]:checked')?.value; 
  // const selectedOptionaccepted= document.querySelector('input[name="requesition_accepted"]:checked')?.value;
  // const selectedOptionrepeat = document.querySelector('input[name="wheather_repeat_test"]:checked')?.value;
  return (
  //   <div className="container">
  // <div className="form-container border-double">
  // <div className="form-data">
  <div className="form-container">
      <div className="form-data">
        
  
<div class="image-container">
  <div class='im'>
    <img src={image} alt="image" class="responsive-image" width="100%" height="100%" />
  </div>


<div class="image-container1">
  <div class='head'>
    <h3>DEFENSE SYSTEMS RESEARCH INSTITUTE</h3>
  </div>

  <div class="containertest">
    <div class="test-requisition">
      <h4>TEST REQUISITION</h4>
    </div>
    
    <div class="form-data">
      <h3>DSRI/F/62</h3>
      
      <div class="form-datadate">
 
 <ul>
   <li><strong>Issue No:</strong> {formData.issuenumber}</li>
   <li><strong>Issue Date:</strong> {formData.issue_date}</li>
   </ul>
   <ul>
   <li><strong>Rev No:</strong> {formData.revise_no}</li>
   <li><strong>Rev Date:</strong> {formData.revise_date}</li>
 </ul>
   
</div>
    </div>
    
  </div>

</div>


  </div>
      {/* <li><strong>control No:</strong> {formData.controlNo}</li> */}
           {/* <li><strong>UID:</strong> {formData.uid}</li> */}
              {/* <li><strong>Date:</strong> {formData.date}</li> */} 
                {/* <ul>
      <li><strong>Date:</strong> <input type="text" value={formData.date} readOnly /></li>
    </ul> */}
</div>
<div className='controlno'>
<ul>
<strong>CONTROL NO:</strong><li> {formData.controlnumber}</li>
<strong>DATE:</strong><li> {formData.date}</li>


<strong>UID NO:</strong> <li>{formData.uid}</li>
{/* <li><strong></strong> {}</li> */}


 </ul>
   
</div>
<div className='formdata3'>
   <div className='formdata2'>
      <ul>
      <strong>Project Unit:</strong> <li>{formData.project}</li>
      <strong>Unit_Under_Test:</strong> <li>{formData.Unit_Under_Test}</li>
        <strong>SI No:</strong> <li>{formData.si_no}</li>
        <strong>Quantity:</strong>  <li>{formData.Qty}</li>
        <strong>Unit Weight (kg):</strong>  <li> {formData.unit_weight}</li>
        <strong>Fixture Weight (kg):</strong>  <li> {formData.fixture_weight}</li>
        <strong>Contact Person:</strong>  <li>{formData.contact_person}</li>
        <strong>Phone Number:</strong> <li> {formData.mobile_no}</li>
        <strong>Company Name:</strong> <li>{formData.company_name}</li>
        <li><strong></strong> {}</li>
      
   
        </ul>
        </div>  
    <h2 className="select-tests-header"> NAME OF TEST    </h2>
    <div>
  <h2 className="select-tests-header">ENVIRONMENTAL TEST:</h2>
  <ul>
    {environmentalTestsList.map((test, index) => (
      <li key={test}> 
        {formData.environment_test && formData.environment_test.includes(test) && (
          <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== environmentalTestsList.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>

     
      
      <div>
  <h2 className="select-tests-header">NOISE & VIBRATION MEASUREMENT TEST:</h2>
  <ul>
    {noiseVibrationTestsList.map((test, index) => (
      <li key={test}> 
        {formData.noise_vibration_measurement_tests && formData.noise_vibration_measurement_tests.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== noiseVibrationTestsList.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>
<div>
  <h2 className="select-tests-header">FLUID FLOW TEST:</h2>
  <ul>
    {fluidFlowTestsList.map((test, index) => (
      <li key={test}>
        <label htmlFor={test}>{test}</label>
        {formData.fluid_flow_test && formData.fluid_flow_test.includes(test) && (
          <span className="tick-mark">&#10003;</span>
        )}
        &nbsp;{index === fluidFlowTestsList.length - 1 ? '_____' : '/'}&nbsp;
      </li>
    ))}
  </ul>
</div>
{/* rdfgvhbknlkmkoi-u08yt86d6rcfgvjbhknkml */}


<div>
 <h2 className="select-tests-header"> Test Standards :</h2>
  <p>{formData.test_standards}</p>
</div>
<div>
<h2 className="select-tests-header"> Test Specifications:</h2>
  <p>{formData.test_specifications}</p>
</div>




{/* 
      <h3>Uncertainty Statement to be stated in Test Report:</h3> */}
      <div>
 <h3>Uncertainty Statement to be stated in Test Report:</h3>
  <ul>
    {uncertainity_statement_in_test_reportlist.map((test, index) => (
      <li key={test}> 
        {formData.uncertainity_statement_in_test_report && formData.uncertainity_statement_in_test_report.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== uncertainity_statement_in_test_reportlist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>
<div>
 <h3>Mention the Statement of conformity :</h3>
  <ul>
    {statement_of_confirmitylist.map((test, index) => (
      <li key={test}> 
        {formData.statement_of_confirmity && formData.statement_of_confirmity.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== statement_of_confirmitylist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>
{/* <div>
      <h3>Mention the Statement of conformity :</h3>
      <ul>
        <li>
          {(selectedOptionmention === 'Yes') ? (
            <span>(<span className="green">&#10003;</span> Yes<>&nbsp;/&nbsp;</>No)</span>
          ) : (
            <span>(Yes/<span className="green">&#10003;</span> No)</span>
          )}
        </li>
      </ul>
    </div> */}


      {/* <h3>Decision Rule (Communicated to and agreed with the customer):</h3> */}
      <div>
 <h3>Decision Rule (Communicated to and agreed with the customer):</h3>
  <ul>
    {decesion_rule_communicated_with_customerslist.map((test, index) => (
      <li key={test}> 
        {formData.decesion_rule_communicated_with_customers && formData.decesion_rule_communicated_with_customers.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== decesion_rule_communicated_with_customerslist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>

<img src={data} alt="image" class="responsive-image" width="100%" height="100%" />
  
    <ul className="case">
  <li>
    {formData.conformance.trim() === 'Case a' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case a : Conformance
  </li>
  <li>
    {formData.conformance.trim() === 'Case b' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case b : Conditional Conformance
  </li>
</ul>
<ul className="case">
  <li>
    {formData.conformance.trim() === 'Case c' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case c : Conditional non-Conformance
  </li>
  <li>
    {formData.conformance.trim() === 'Case d' && (
      <span className="tick-mark">&#10003;</span>
    )}
    Case d : non-Conformance
  </li>
</ul>

{/* <h5> If Conformity statement required the customer has to specify in the test request.</h5> */}
<div>
 <h3>If Conformity statement required the customer has to specify in the test request :</h3>
  {/* <ul>
    { statement_of_confirmitylist.map((test, index) => (
      <li key={test}> 
        {formData. statement_of_confirmity && formData. statement_of_confirmity.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !==  statement_of_confirmitylist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div> */}
  <ul>
    { specifyList.map((test, index) => (
      <li key={test}> 
        {formData.specify && formData.specify.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !==  specifyList.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>



      {/* <h3>Record of Change Request(s)(if any):</h3> */}
      <div>
 <h3>Record of Change Request(s)(if any):</h3>
  <ul>
    {record_of_change_requestlist.map((test, index) => (
      <li key={test}> 
        {formData.record_of_change_request && formData.record_of_change_request.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== record_of_change_requestlist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>

     
    <div>
       <p className='re'><strong>If Yes Pls. Specify:</strong> {formData.if_yes_pls_specify}</p>
       </div>
    <div>
    <li className='re'><strong>Requested By:</strong> {formData.requested_by}</li>
    </div>
    <div className='signature'>
      <div>  <h3>Signature(Inspected By)</h3> 
       
    <h3><strong>Name:</strong> {formData.inspectedBySignature}</h3>
    </div>
    <div>
        <h3>Signature(Customer)</h3>  
       
    <h3><strong>Name:</strong> {formData.customerSignature}</h3>
    </div>
     
       </div>

       </div>
       <div className='formdata3d'>
       <div className='formdata2d'>
       <h2 className="select-tests-header1">(TO BE FILLED BY DSRI) </h2>
      <ul>
      <strong>Test Start Date:</strong> <li>{formData.test_start_date}</li>
      <strong>UUT Receipt Date:</strong> <li>{formData.uut_receipt_date}</li>
        <strong>Completion Date:</strong> <li>{formData.test_completion_date}</li>
        {/* <strong>Requisition Accepted</strong>  <li>{formData.Qty}</li> */}
       
      {/* <h3>Requisition Accepted :</h3> */}


      <div>
 <h3>Requisition Accepted :</h3>
  <ul>
    {requesition_acceptedlist.map((test, index) => (
      <li key={test}> 
        {formData.requesition_accepted && formData.requesition_accepted.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !== requesition_acceptedlist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}

  </ul>
</div>




      <strong>Test Equipment:</strong>  <li> {formData.test_equipment}</li>
    
      {/* <h3>Whether Repeat test :</h3> */}
      <div>
 <h3>Whether Repeat test :</h3>
  <ul>
    { wheather_repeat_testlist.map((test, index) => (
      <li key={test}> 
        {formData. wheather_repeat_test && formData. wheather_repeat_test.includes(test) && (
           <span className="tick-mark">&#10003;</span>
        )}
        <span>{test}</span>
        {index !==  wheather_repeat_testlist.length - 1 && <>&nbsp;/&nbsp;</>}
      </li>
    ))}
  </ul>
</div>
   

        {/* <strong>Whether Repeat test:</strong>  <li> {formData.fixture_weight}</li> */}
        <strong>Total Time of Equipment Use:</strong>  <li>{formData.total_time_of_equipment_use}</li>
        {/* <strong>Remarks:</strong> <li> {formData.remarks}</li> */}
        <div>
 <h2 className="select-tests-header"> Remarks :</h2>
  <p>{formData.remarks}</p>
</div>
        {/* <strong>Technical Manager:</strong> <li>{formData.company_name}</li> */}
        <h2 className='tm'>Technical Manager</h2>
        <li><strong></strong> {}</li>
      
   
        </ul>
        </div> 
        </div> 




        
       <div className='formdata2dl'>
       <h2 className="select-tests-header1">TO FILLED AFTER COMPLETION OF TEST </h2>
      <ul>
      <strong>Unit condition(pass/fail)</strong> <li>{formData.unit_condition}</li>
      
      
        <div>
 <h2 className="select-tests-header"> Remarks If Any :</h2>
  <p>{formData.remarks_if_any}</p>
</div>
        {/* <strong>Technical Manager:</strong> <li>{formData.company_name}</li> */}
        <h2 className='tm'>Signature (Customer)</h2>
        <h3 className='tnd'>Name & Date:</h3>
        <li><strong></strong> {}</li>
      
   
        </ul>
        </div> 
        <div className='formdata3d'>
        <h2 className="select-tests-header1">TERMS AND CONDITIONS</h2>
       <p>1.	The testing facility at the Laboratory is available for equipment/products/samples development and document (Test reports) as per the customer’s requirements.</p> 
       <p>2.	The terms and conditions for testing at this laboratory including the responsibilities of the customers in getting their equipment/products/samples to be tested, services to be rendered by the Testing laboratory.</p> 
       <p>3.	The customer who desires his equipment/products/samples to be tested, shall make a request in the writing to the laboratory giving adequate details of the equipment/products/samples to be tested, standards to be followed and the details of the tests to be conducted along with referred date of testing.</p> 
       <p>4.	The Testing Authority shall provide the customer program of testing. In case of suspension, postponement or cancellation of the tests due to outage of the laboratory, the Testing Authority will not, however, in anyway be liable for the losses that may be incurred by the customer due to delays or postponement resulting from such outage.</p> 
       <p>5.	The customer shall arrange at his own cost to transport and receive at the laboratory their equipment/product/samples at least one day before the date of commencement of the test. The customer is responsible for the handling of his equipment/products/samples.</p> 
       <p>6.	The customer is responsible for arranging fixtures, if any required for mounting his equipment/products in the test bay. The customer shall observe safety instructions in the test bays while installation of equipment/product/samples and dismantling.</p> 
       <p>7.	The customer shall indicate while filling up the Customer Requisition Form whether the equipment/product/samples submitted for testing will be taken back after testing or will be left behind. Customer to remove from DSRI premises such equipment/product/samples within 07 days after completion of testing, after 07 charges applied.</p> 
       <p>8.	The DSRI ET Lab issue the TEST REPORT at no extra cost, but only upon payment of the final bill of test charges, when an
equipment/product/sample of a particular type and rating, in the following situations:</p> 
<p>•	In case of failure in any one of the tests in the test sequence in compliance with the conditions stipulated in a published
National/International Standards. In case all the tests stipulated in compliance with published National/International Standards are not carried out in DS
</p>
          </div>
          <div className='formdata3dl'>
            <h4>RoadNo:04,PlotNo:21,IDAMallapur,IENacharam,Hyderabad-500076,TS Fax: </h4>
            <h4>040-27155843, GST IN: 36AAKCD4650Q1Z1</h4>
           <h4> Website:	https://dsri.co.in/	Email:	info@dsri.co.in</h4>
          </div>
      </div>


  
  );
}

export default Formdata;

